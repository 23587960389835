<script setup>
import Checkbox from '@/Components/Checkbox.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/InputError.vue'
import InputLabel from '@/Components/InputLabel.vue'
import PrimaryButton from '@/Components/PrimaryButton.vue'
import TextInput from '@/Components/TextInput.vue'
import { Head, Link, useForm } from '@inertiajs/vue3'
import { ref } from 'vue'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'

const passwordVisibility = ref('password')

function togglePasswordVisibility (toggle = false) {
    passwordVisibility.value = toggle ? 'text' : 'password'
}

defineProps({
    canResetPassword: {
        type: Boolean
    },
    status: {
        type: String
    }
})

const form = useForm({
    email: '',
    password: '',
    remember: false
})

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password')
    })
}
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />

        <h1 class="text-5xl font-bold mt-16 sm:mt-16 mb-10">Log In</h1>

        <div
            v-if="status"
            class="mb-4 font-medium text-sm text-green-success"
        >
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div class="relative z-0 w-full mb-6 group">
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="username"
                />
                <InputLabel
                    for="email"
                    value="Email"
                />

                <InputError
                    class="mt-2"
                    :message="form.errors.email"
                />
            </div>

            <div class="relative z-0 w-full mb-6 group">
                <EyeIcon
                    v-show="passwordVisibility == 'password'"
                    class="w-5 h-5 absolute top-4 right-0"
                    @click="togglePasswordVisibility(true)"
                />
                <EyeSlashIcon
                    v-show="passwordVisibility == 'text'"
                    class="w-5 h-5 absolute top-4 right-0"
                    @click="togglePasswordVisibility()"
                />
                <TextInput
                    id="password"
                    v-model="form.password"
                    :type="passwordVisibility"
                    class="mt-1 block w-full"
                    required
                    autocomplete="current-password"
                />
                <InputLabel
                    for="password"
                    value="Password"
                />
                <InputError
                    class="mt-2"
                    :message="form.errors.password"
                />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox
                        v-model:checked="form.remember"
                        name="remember"
                    />
                    <span class="ml-2 text-sm text-gray-600">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-between mt-4 gap-1">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm text-gray-600 hover:text-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Forgot your password?
                </Link>

                <PrimaryButton
                    class="w-28 justify-center"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    Log in
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>
